<template>
  <div class="software-version-page-wrapper">
    <el-row class="row title">
      <el-col>
        版本号 :
        <span style="color:#409eff">{{ softVersion.versionName }}</span>
      </el-col>
    </el-row>
    <el-row class="row">
      更新内容 :
      <ul>
        <li v-for="(item, index) in content" :key="index">
          <div style="margin-left:60px;padding:10px 20px;border-bottom: 1px solid #34373d;">
            {{ item }}
          </div>
        </li>
      </ul>
    </el-row>
    <el-row class="row">更新时间 : {{ softVersion.updateTime }}</el-row>
  </div>
</template>

<script>
import { getAppVersionInformation } from '@/api/app.js';
export default {
  name: 'SoftVersion',
  data() {
    return {
      softVersion: {},
      content: [],
    };
  },
  mounted() {
    this.getSoftVersion();
  },
  methods: {
    getSoftVersion() {
      getAppVersionInformation()
        .then(resopnse => {
          const { data } = resopnse;
          this.softVersion = data;
          this.content = data.updateContent.split('|');
        })
        .catch(error => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.software-version-page-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  background: #{$cardBg};
  color: #fff;
  .row {
    padding: 20px;
  }
  .title {
    font-size: 18px;
    border-bottom: 1px solid #34373d;
  }
  h3 {
    width: 100%;
    background: #2d3035;
    padding: 20px;
    margin: 0;
    box-sizing: border-box;
    border-bottom: 1px solid #34373d;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  ul {
    flex: 1;
    margin: 0;
    padding: 10px;
    font-size: 14px;
    background: #{$cardBg};
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    > li {
      margin: 10px;
    }
  }
}
</style>
