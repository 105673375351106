import request from '@/utils/request';
import { base_url } from '@/utils/BASE_URL';
const server = base_url.server;


function getAppVersionInformation() {
  return request({
    url: `${server}/${base_url.core}/softs/newversion`,
    method: 'GET'
  })
}

export {
  getAppVersionInformation
}